@font-face {
  font-family: "CustomFont";
  src: url("/public/fonts/RexliaRg-Regular.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "CustomFont";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: whitesmoke;
}

.App-heading-text-color {
  color: #3b9cfb;
}

.App-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-section-col {
  padding: 32px !important;
}

.App-homepage-bg {
  min-height: calc(100vh - 56px);
  background-image: url("./images/gym-photo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  align-content: center;
  background-blend-mode: hue;
}

.App-performance-section-image-dumbbell {
  min-height: 80vh;
  background-image: url("./images/gym-dumbbell.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
}

.App-performance-section-image-barbell {
  min-height: 80vh;
  background-image: url("./images/gym-barbell.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1210px) {
  div.App-section-col {
    min-width: 600px;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
  div.Reverse {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 991px) {
  div.App-section-col {
    min-width: 600px;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}

@media screen and (max-width: 768px) {
  div.App-section-col {
    min-width: 400px;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}

@media screen and (max-width: 435px) {
  div.App-section-col {
    min-width: 280px;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}

@media screen and (max-width: 280px) {
  div.App-section-col {
    min-width: 280px;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
